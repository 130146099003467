import { useEffect, useState } from "react";
import "./App.css";
import logo from "./logo.png";
import { TypeAnimation } from "react-type-animation";

function App() {
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoaded(true);
    }, 5000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      {loaded ? (
        <>
          <div
            style={{
              position: "absolute",
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              // backgroundColor:"#002d42"
            }}
          >
            <img
              style={{
                minWidth: "300px",
                width: "30%",
              }}
              src={logo}
              alt="edvisemelogo"
            />
          </div>
          <div className="BackgroundDiv">
            <div
              style={{
                width: "60%",
                minWidth: "300px",
                maxWidth: "1000px",
                textAlign: "center",
              }}
            >
              <h1
                style={{
                  marginBottom: "0px",
                  textShadow: "1px 1px 2px #979797",
                }}
              >
                Revolutionizing College Admissions with AI
              </h1>
            </div>
            <div
              style={{
                width: "60%",
                minWidth: "300px",
                maxWidth: "600px",
                textAlign: "center",
              }}
            >
              <p
                className="par"
                style={{
                  textAlign: "center",
                  textShadow: "1px 1px 2px #979797",
                }}
              >
                Earn scholarships with personalized college research and get
                discovered by colleges with direct admission offers
              </p>
            </div>
            <TypeAnimation
              sequence={[
                // Same substring at the start will only be typed once, initially
                "Coming Soon...",
                4000,
              ]}
              speed={1}
              style={{
                fontSize: "calc(15px + 2vmin)",
                color: "#ff424d",
                textShadow: "1px 1px 2px #83181E",
              }}
              repeat={Infinity}
            />
          </div>
        </>
      ) : (
        <div
          style={{
            width: "100%",
            height: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <span className="loader"></span>
        </div>
      )}
    </>
  );
}

export default App;
